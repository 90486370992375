
export default {
	name: "RadioButton",
	model: {
		prop: "model_data",
		event: "change"
	},
	props: {
		model_data: {},
		items: {
			type: Array,
			default: function() {
				return [];
			}
		},
		rules: {
			type: Array,
			default: function() {
				return [];
			}
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		row: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		padding: {
			type: String,
			default: "px-2 py-0"
		},
		classStyles: {
			type: String,
			default: "radio-button"
		},
		dataCy: {
			type: String,
			default: ""
		},
	},
	data: function() {
		return {};
	},
	created() {
		// console.log("created::RadoButton", { rules: this.rules });
	},
	mounted() {},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("change", val);
			},
			get() {
				return this.model_data;
			}
		}
	},
	methods: {}
};
