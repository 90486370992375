
import { debounce } from "lodash";
import Vue from "vue";
import { SortingOption } from "../../../interfaces/paginated";
export default Vue.extend({
	name: "Filterable",
	props: {
		header: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		field_name: {
			type: String,
		},
		filter_by_active: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "text",
		},
		option_remove: {
			type: Boolean,
			default: false,
		},
		notEqualFields: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data: function () {
		return {
			filter: "",
		};
	},
	created() {},
	mounted() {},
	computed: {
		verifyByFields() {
			let isValid: Boolean = false;

			switch (this.options.sort) {
				case "country_name":
					isValid = this.header.value == "country";
					break;

				case "total_pois":
					isValid = this.header.value == "total_count_pois";
					break;

				case "country_name":
					isValid = this.header.value == "country";
					break;

				case "status.description":
					isValid = this.header.value == "status";
					break;

				case "total_errors":
					isValid = this.header.value == "error_pois";
					break;

				case "created_at":
					isValid = this.header.value == "create_date";
					break;
			}

			return isValid;
		},
		getColors() {
			if (this.notEqualFields) {
				return {
					sorted: this.verifyByFields,
					unsorted: !this.verifyByFields,
				};
			}
			return {
				sorted: this.header.value == this.options.sort,
				unsorted: this.header.value != this.options.sort,
			};
		},
		isfieldSortabled() {
			if (this.notEqualFields) {
				return this.verifyByFields;
			}
			return this.header.value == this.options.sort;
		},
	},
	methods: {
		selectedOption: debounce(function () {
			const params = {
				options: {
					sort: this.field_name,
					order: this.options.order,
				} as SortingOption,
				filter: this.filter,
			};
			this.$emit("selected-option", params);
		}, 500),
		removeOption: debounce(function () {
			this.handleResetOptFilter();
		}, 500),
		handleOrder(options: SortingOption) {
			options.order = options.order == "asc" ? "desc" : "asc";
			const params = {
				options: options,
				filter: this.filter,
			};
			this.$emit("selected-option", params);
		},
		handleResetOptFilter() {
			this.filter = undefined;
			this.$emit("remove-option");
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				if (Object.keys(this.filters).length) return;
				this.filter = "";
			},
		},
	},
});
